<template>
	<main>
		<el-tabs v-model="tab_name">
			<el-tab-pane label="官网" name="home"></el-tab-pane>
			<el-tab-pane label="邮箱配置" name="smtp"></el-tab-pane>
		</el-tabs>
		<div class="form-box">
			<el-form ref="form" :model="form" label-width="140px">
				<div class="form-tab" v-show="tab_name === 'home'">
					<el-form-item label="手机号码" prop="home_phone">
						<el-input v-model="form.home_phone"/>
					</el-form-item>
					<el-form-item label="QQ" prop="home_qq_number">
						<el-input v-model="form.home_qq_number"/>
					</el-form-item>
					<el-form-item label="邮箱" prop="home_email">
						<el-input v-model="form.home_email"/>
					</el-form-item>
					<el-form-item label="地址" prop="home_address">
						<el-input v-model="form.home_address"/>
					</el-form-item>
					<el-form-item label="备案" prop="home_beian">
						<el-input v-model="form.home_beian"/>
					</el-form-item>
				</div>
				<div class="form-tab" v-show="tab_name === 'smtp'">
					<el-form-item label="SMTP服务器" prop="smtp_host">
						<el-input v-model="form.smtp_host"/>
					</el-form-item>
					<el-form-item label="SMTP端口" prop="smtp_port">
						<el-input v-model="form.smtp_port"/>
					</el-form-item>
					<el-form-item label="SMTP账号" prop="smtp_account">
						<el-input v-model="form.smtp_account"/>
					</el-form-item>
					<el-form-item label="SMTP密码" prop="stmp_password">
						<el-input v-model="form.smtp_password"/>
					</el-form-item>
					<el-form-item label="发件人" prop="stmp_user">
						<el-input v-model="form.smtp_user"/>
					</el-form-item>
					<el-form-item label="" >
						<el-button type="primary" @click="toTest">点击发送测试邮件</el-button>
					</el-form-item>
				</div>
			</el-form>
		</div>
		<el-button type="primary" @click="submit">保存</el-button>
	</main>
</template>
<script>
import {config,email} from "@/api/admin";
import {MessageBox} from "element-ui";
import {checkString} from "@/common/type";

export default {
	data(){
		return {
			tab_name:"home",
			form:{}
		}
	},
	mounted() {
		this.get();
	},
	methods:{
		toTest(){
			this.submit();
			this.$prompt('请输入收件人邮箱','提示').then(({value})=>{
				if(!checkString(value,'email')){
					this.$message.error('邮箱格式不正确');
					return false;
				}
				email.test({
					to:value
				}).then((res)=>{
					this.$message.success('发送成功')
				})
			})
		},
		get(){
			config.get().then((res)=>{
				this.form = res.detail || {};
			})
		},
		submit(){
			config.create(this.form).then((res)=>{
				this.$message.success('保存成功')
			})
		}
	}
}
</script>
